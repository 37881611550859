@import "../../../assets/scss/setup/setup.scss";

.Loader {
  display: inline-block;
  position: relative;
  width: 200px;
  height: 200px;

  &__container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  div {
    position: absolute;
    border: 4px solid $c-green;
    opacity: 1;
    border-radius: 50%;
    animation: lds-ripple 1.3s cubic-bezier(0, 0.2, 0.8, 1) infinite;

    &:nth-child(2) {
      animation-delay: -0.8s;
    }
  }
}

@keyframes lds-ripple {
  0% {
    top: 100px;
    left: 100px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  4.9% {
    top: 100px;
    left: 100px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  5% {
    top: 100px;
    left: 100px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 200px;
    height: 200px;
    opacity: 0;
  }
}
