@import "../../assets/scss/setup/setup.scss";


.Search {
    &__container {
        background: #FAFAFA;
        width: 100%;
        height: 216px;
        display: flex;
        justify-content: center;
        align-items: center;

        &__inner {
            width: 80%;
            height: 85%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;



            label {
                font-family: $f-family;
                font-style: normal;
                font-weight: 500;
                font-size: 18px;
                line-height: 25px;
                color: $c-black;
            }
        }
    }

    &__buttonContainer {
        display: flex;
        justify-content: flex-end;
    }

    &__reset {
        text-transform: uppercase;
        color: #579D36;
        font-size: 12px;
        font-style: normal;
        font-weight: 800;
        line-height: 25px;
        margin-left: 5px;
        cursor: pointer;
    }

    &__inputError {
        line-height: 15px;
        display: block;
    }

    &__reset:hover {
        text-decoration: underline;
    }

    &__button {
        margin-top: 6%;
    }
}

.Table {
    &__container {
        width: 100%;
        display: flex;
        justify-content: center;

        &__inner {
            width: 80%;
        }
    }

    &__iconContainer {
        display: flex;
        flex-direction: row;
        width: 100%;

        div {
          cursor: pointer;

          margin-right: 10px;

          &:last-child {
            margin-right: 0; /* Ensures that the last icon doesn't have unnecessary margin on the right */
          }
        }
      }
}

label {
    font-size: 12px !important;
    line-height: 10px !important;
}

.Modal {
    &__Body {
        // width: 100%;
        // height: 90vh;
        max-height: 100vh;
        border-radius: 10px;

        @include medium-size-max {
            max-width: 100vw;
            height: 100vh;
            border-radius: 0px;
        }
    }


    &__Button {
        width: 250px;
        margin: auto;
    }


    &__Content {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        gap: 20px;
        width: 100%;
        max-width: 500px;

        label {
            font-family: $f-family;
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 25px;
            color: $c-black;
        }
    }

    &__limit {
        width: 40%;
        margin-left: 50px;
    }

    &__Mb {

    }
}

.Actions {

    &__container {
        display: flex;
        justify-content: center;
        align-items: center;
        width:100%;
        height: 100px;
    }

    &__inner {
        display: flex;
        justify-content: space-between;
        width: 80%;
    }

    &__dropdown {
        width: 450px;
        display: flex;
        justify-content: space-between;

        button {
            margin-left: 10px;
        }

        &>div {
            width: 250px;
        }
    }
}