@import "../../../assets/scss/setup/setup.scss";

.Table {
  width: 100%;
  overflow: auto;
  position: relative;

  table {
    width: 100%;
    border-collapse: collapse;
    text-align: left;
  }

  thead {
    tr {
      border-bottom: 1px solid $c-white;
    }
  }

  th {
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    line-height: 19px;
    border-right: 1px solid $c-white;
    color: $c-table-head;
    padding: 22px 12px;
    background-color: $bg-table-head;

    &:last-child {
      border-right: 0;
    }
  }

  td {
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    line-height: 19px;
    padding: 15px 10px;
    color: $c-table-body;

    a {
      color: inherit;
      word-break: break-word;
    }
  }

  tbody {
    tr {
      border-top: 1px solid $bg-table-head;
      background-color: $c-white;
    }

    tr:nth-child(odd) {
      background-color: $bg-table-body;
    }
  }
}

.Loading {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: $bg-table-head;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 800;
  font-size: 14px;
  line-height: 19px;
  color: $c-table-body;
}
