@import "../../../assets/scss/setup/setup.scss";

.Info {
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 19px;
  padding-bottom: 15px;
  color: $c-table-body;
}

.Footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;

  @include small-size-max {
    flex-direction: column-reverse;
    align-items: flex-start;
  }
}

.Link {
  @include small-size-max {
    margin-top: 20px;
  }
}

.Action {
  text-decoration: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  svg {
    width: 18px;
    height: auto;
  }

  span {
    margin-left: 5px;
  }
}