@import "../../../assets/scss/setup/setup.scss";

.Notification {
  font-family: $f-family;
  position: fixed;
  z-index: 800;
  right: 0px;
  animation: move;
  animation-duration: 1s;
  animation-fill-mode: forwards;

  &__wrapper {
    color: $c-white;
    border-radius: 7px;
    margin: 20px;
    padding: 20px;
    max-width: 475px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &.error {
      background: $c-error-text;
    }

    &.success {
      background: $c-green;
    }
  }

  &__text,
  &__btn {
    font-size: 16px;
    line-height: 22px;
  }

  &__text {
    font-weight: 400;
  }

  &__btn {
    font-weight: 800;
    padding: 0 15px;
    cursor: pointer;
  }

  &.full {
    width: 100%;
    
    .Notification__wrapper {
      max-width: 100%;
    }
  }
}

@keyframes move {
  0% {
    bottom: -100px;
  }

  100% {
    bottom: 0px;
  }
}
