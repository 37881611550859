@import "../../../assets/scss/setup/setup.scss";

.select_container {
  width: 100%;

  label {
    color: $c-input-color;
    display: block;
    margin-bottom: 5px;
    font-size: 18px;
    font-weight: 300;
    line-height: 25px;
    padding-left: 1px;
  }

  select {
    display: block;
    outline: none;
    appearance: none;
    box-sizing: border-box;
    font-size: 16px;
    line-height: 22px;
    color: $c-black;
    margin: 0;
    padding: 12px 30px 12px 15px;
    min-width: 190px;
    width: 100%;
    background-color: $c-white;
    border: 1px solid $c-input-border;
    border-radius: 5px;
    transition: all 300ms ease-in-out;
    text-overflow: ellipsis;

    &:disabled {
      background: rgba($c-input-disabled, 0.5);
      color: rgba($c-black, 0.4);
      cursor: not-allowed;
    }
  }

  .select {
    position: relative;

    &_icon {
      position: absolute;
      top: 12px;
      right: 6px;
      color: $c-input-border;
      pointer-events: none;
    }

    &__error {
      margin-left: 15px;
      color: $c-error-text;
      font-size: 14px;
    }
  }

  &.invalid {
    select {
      border: 1px solid $c-error-border;
      background-color: $c-error-bg;
    }
  }

  &.small {
    position: relative;
    label {
      color: $c-black;
      font-size: 12px;
      font-weight: 500;
      line-height: 16px;
    }

    .select {
      &_icon {
        color: $c-black;
      }
    }
  }

  .placeholder select {
    color: #999999;
  }
}

.Icon {

  &__cancel {
    position: absolute !important;
    right: 0px;
    top: -13px;
    svg {
      width: 16px;
      height: 16px;
    }
  }
}
