@import "../../../assets/scss/setup/variables.scss";

.Scrollbars {
    display: grid;
    height: 100%;
    grid-template: auto / 1fr 25px;
    overflow: hidden;
    position: relative;

    &__no_scrollbar {
        grid-template: auto / 1fr 0px;
        .Scrollbars__scrollbar {
            visibility: hidden;
        }
    }

    &__content {
        height: 400px;
        -ms-overflow-style: none;
        overflow: auto;
        scrollbar-width: none;
    }
    
    &__content::-webkit-scrollbar {
        display: none;
    }
    
    &__scrollbar {
        display: grid;
        gap: 1rem;
        grid-auto-flow: row;
        // grid-template: auto 1fr auto / 1fr;
        grid-template: 1fr / 1fr;
        place-items: center;
    }
    
    &__track_thumb {
        display: block;
        height: 100%;
        position: relative;
        width: 8px;
        margin-left: auto;
    }
    
    &__track {
        background-color: $c-input-border;
        border-radius: 12px;
        bottom: 0;
        cursor: pointer;
        position: absolute;
        top: 0;
        width: 8px;
    }
    
    &__thumb {
        border-radius: 12px;
        background-color: $c-green;
        position: absolute;
        width: 8px;
        touch-action: none;
    }
}
