@import "../../../assets/scss/setup/setup.scss";

.OrgInfo {
  margin-top: 50px;
  margin-bottom: 50px;

  &__Col {
    will-change: transform, opacity;
    @include medium-size-max {
      &:nth-child(n+3) {
        margin-top: 30px;
      }
    }

    @include xs-size-max {
      &:nth-child(n+2) {
        margin-top: 30px;
      }
    }

    &_Inner {
      border: 1px solid $c-border;
      height: 130px;
      border-radius: 15px;
      display: flex;
      overflow: hidden;
      text-decoration: none;

      &_Left {
        width: 40%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        background-image: url("../../../assets/images/rectangular-arrow.svg");
        background-size: cover;
        background-position: right;

        img {
          @include extra-large-size-max {
            max-width: 40px;
            width: 100%;
            max-height: 40px;
          }

          @include medium-size-max {
            max-width: 60px;
            max-height: 60px;
          }
        }
      }

      &_Right {
        width: 60%;
        padding-right: 15px;
        display: flex;
        flex-direction: column;
        justify-content: center;

        h2 {
          text-align: right;
          color: $c-icon;
          font-size: 26px;
          font-weight: 300;

          @include extra-large-size-max {
            font-size: 22px;
          }

          @include large-size-max {
            font-size: 18px;
          }

          @include medium-size-max {
            font-size: 26px;
          }

          @include small-size-max {
            font-size: 20px;
          }
        }

        p {
          text-align: right;
          color: $c-black;
          font-size: 30px;
          font-weight: 300;
          margin: 0;

          @include extra-large-size-max {
            font-size: 26px;
          }

          @include large-size-max {
            font-size: 22px;
          }

          @include medium-size-max {
            font-size: 30px;
          }

          @include small-size-max {
            font-size: 24px;
          }
        }
      }
    }
  }
}