@import "../../../assets/scss/setup/setup.scss";

.Activity {
  display: flex;
  margin-top: 23px;

  &:first-child {
    margin-top: 0;
  }

  &__icon {
    width: 24px;
    height: auto;
    margin-right: 23px;

    svg {
      width: 24px;
      height: auto;
    }
  }

  &__text {
    font-family: $f-family;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 22px;
    color: $c-black;
    display: inline;
  }

  &__time {
    color: $c-gray;
    font-weight: 800;
    font-size: 12px;
    line-height: 16px;
    margin-left: 12px;
    text-transform: uppercase;
  }

  &__more {
    display: block;
    margin-top: 30px;
    margin-left: 47px;
  }

  &__wrap {
    max-height: 360px;
    overflow: auto;
  }
}
