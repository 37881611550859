@import "../../../assets/scss/setup/setup.scss";

.EBL {
  margin-top: 50px;
  margin-bottom: 50px;

  &_Wrp {
    padding: 25px;
    border: 1px solid $c-border;
    border-radius: 15px;
  }

  &_Col {
    &_Title {
      margin-bottom: 25px;

      &_Title {
        color: $c-black;
        font-size: 30px;
        font-weight: 300;
        margin: 0;
      }

      &_Filter {
        &_Label {
          color: #000;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          margin-bottom: 5px;
        }

        &_Field {
          border: 1px solid $c-border;
          border-radius: 5px;
          height: 50px;
          color: $c-black;
          font-size: 14px;
        }
      }
    }

    &_Count {
      p {
        color: $c-gray-2;
        font-weight: 900;
        font-size: 12px;
        line-height: 1;
        margin-bottom: 10px;
      }
    }
  }

  &_Row {
    &_Pagination {
      margin-top: 25px;
    }
  }

  &_Table {
    box-shadow: none!important;

    &_Link {
      color: $c-green;
      text-decoration: none;
      font-weight: 900;
      font-size: 16px;
      margin-left: 5px;

      &:hover {
        text-decoration: underline;
        color: $c-green;
      }
    }
  }
}