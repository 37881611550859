@import "../../assets/scss/setup/setup.scss";

.Heading {
  background: $c-lime;
  padding: 40px 0;

  &__wrap {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    @include small-size-max {
      flex-direction: column;
      text-align: center;
    }
  }

  &__content {
    @include large-size-max {
      display: flex;
      flex-direction: column;

      @include medium-size-min {
        flex-direction: row;
      }
    }
  }

  &__titleContainer {
    display: flex;
    flex-direction: column;
  }

  &__subtitle {
    color: $c-black;
    font-family: $f-family;
    font-style: light;
    font-weight: 400;
    font-size: 16px;
    margin-right: 50px;
    margin-bottom: 0;
    text-transform: uppercase;

    @include small-size-max {
      margin-right: 0;
    }
  }

  &__title {
    will-change: transform, opacity;
    color: $c-black;
    font-family: $f-family;
    font-style: normal;
    font-weight: 900;
    font-size: 36px;
    line-height: 49px;
    margin-right: 50px;
    margin-bottom: 0;

    @include small-size-max {
      margin-right: 0;
    }
  }

  &__subtitle {
    text-transform: uppercase;
    font-size: 18px;

    @include large-size-max {
      margin-top: auto;
      margin-bottom: 6px;
    }

    @include extra-large-size-min {
      line-height: 18px;
      position: absolute;

    }
  }

  &__description {
    will-change: transform, opacity;
    font-family: $f-family;
    font-style: normal;
    font-weight: 300;
    font-size: 36px;
    line-height: 49px;
    color: $c-black;
    margin-right: auto;
    margin-bottom: 0;
  }

  &__btn {
    margin: 10px 0;
    min-width: 150px;

    >button {
      width:100%;
    }

    @include small-size-max {
      margin: 10px 0 0;
    }
  }
}
