// MEDIA QUERY TABLET PORTRAIT
//max-width: 565
@mixin xs-size-max {
  @media screen and (max-width: 575px) {
    @content;
  }
}

//min-width: 300
@mixin xs-size-min {
  @media screen and (min-width: 300px) {
    @content;
  }
}

@mixin xs-size-all {
  @media screen and (min-width: 300px) and (max-width: 575px) {
    @content;
  }
}

// MEDIA QUERY TABLET PORTRAIT
//max-width: 767
@mixin small-size-max {
  @media screen and (max-width: 767px) {
    @content;
  }
}

//min-width: 576
@mixin small-size-min {
  @media screen and (min-width: 576px) {
    @content;
  }
}

@mixin small-size-all {
  @media screen and (min-width: 576px) and (max-width: 767px) {
    @content;
  }
}

// MEDIA QUERY TABLET LANDSCAPE
//max-width: 991
@mixin medium-size-max {
  @media screen and (max-width: 991px) {
    @content;
  }
}

//min-width: 768
@mixin medium-size-min {
  @media screen and (min-width: 768px) {
    @content;
  }
}

@mixin medium-size-all {
  @media screen and (min-width: 768px) and (max-width: 991px) {
    @content;
  }
}

// MEDIA QUERY DESKTOP
//max-width:1999
@mixin large-size-max {
  @media screen and (max-width: 1199px) {
    @content;
  }
}

//min-width:992
@mixin large-size-min {
  @media screen and (min-width: 992px) {
    @content;
  }
}

@mixin large-size-all {
  @media screen and (min-width: 992px) and (max-width: 1199px) {
    @content;
  }
}

// Extra large
@mixin extra-large-size-max {
  @media screen and (max-width: 1399px) {
    @content;
  }
}

@mixin extra-large-size-min {
  @media screen and (min-width: 1200px) {
    @content;
  }
}

@mixin extra-large-size-all {
  @media screen and (min-width: 1200px) and (max-width: 1399px) {
    @content;
  }
}

// XX-Large
@mixin xx-large-size-min {
  @media screen and (min-width: 1400px) {
    @content;
  }
}

// USAGE MEDIA QUERY
// @include medium-size-max {
//  properties
// }
