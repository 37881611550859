@import "../../../assets/scss/setup/setup.scss";

.Checkbox {
  display: flex;
  input[type="checkbox"] {
    margin: 0;
    padding: 0;
    cursor: pointer;
    appearance: none;
    outline: none;
    height: 17px;
    width: 17px;
    min-width: 17px;
    background: $c-white;
    border: 2px solid $c-input-border;
    border-radius: 4px;
    box-sizing: border-box;
    background-repeat: no-repeat;
    background-position: center;
    transition: background-color 0.15s ease-in-out,
      background-position 0.15s ease-in-out, border-color 0.15s ease-in-out,
      box-shadow 0.15s ease-in-out;
  }

  input[type="checkbox"] {
    &:checked {
      background-color: $c-green;
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 13 11' width='13' height='11' fill='none'%3e%3cpath d='M11.0426 1.02893C11.3258 0.695792 11.8254 0.655283 12.1585 0.938451C12.4917 1.22162 12.5322 1.72124 12.249 2.05437L5.51985 9.97104C5.23224 10.3094 4.72261 10.3451 4.3907 10.05L0.828197 6.88335C0.50141 6.59288 0.471975 6.09249 0.762452 5.7657C1.05293 5.43891 1.55332 5.40948 1.88011 5.69995L4.83765 8.32889L11.0426 1.02893Z' fill='%23FFFFFF'/%3e%3c/svg%3e");
      border-color: $c-green;
    }
  }

  label {
    display: flex;
    align-items: center;

    font-family: $f-family;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 25px;
    color: $c-input-color;
  }

  span {
    margin-left: 15px;
  }
}

Icon {
  position: absolute;
  bottom: 6px;
  right: 11px;
  pointer-events: none;

  &__cancel {
    position: absolute !important;
    right: 0px;
    top: -13px;
    svg {
      width: 16px;
      height: 16px;
    }
  }
}