@import "../../assets/scss/setup/setup.scss";

.Card {
  will-change: transform, opacity;
  padding: 35px;
  border: 1px solid $c-border;
  border-radius: 5px;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
}
