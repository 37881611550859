@import "../../../assets/scss/setup/setup.scss";

.Modal {
  font-family: $f-family;
  position: fixed;
  z-index: 800;
  bottom: 0px;
  right: 0px;
  left: 0px;
  top: 0px;

  &__overlay {
    position: fixed;
    bottom: 0px;
    right: 0px;
    left: 0px;
    top: 0px;
    background: rgba($c-black, 0.4);
    will-change: opacity;
  }

  &__wrapper {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__body {
    max-height: 80vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    background: $c-white;
    will-change: opacity transform;


    @include small-size-max {
      width: 100%;
      height: 100vh;
      max-height: 100vh;
      padding: 50px 0;
    }
  }

  &__header {
    padding: 25px 40px;
  }

  &__content {
    // min-width: 500px;
    padding: 0 40px 25px 40px;
    overflow: auto;

    @include small-size-max {
      min-width: auto;
    }
  }

  &__close {
    position: absolute;
    top: 20px;
    right: 20px;
    display: flex;
    color: $c-close-btn;
    font-weight: 500;
    font-size: 24px;
    line-height: 24px;
    cursor: pointer;

    @include small-size-max {
      top: 50px;
    }
  }

  &__title {
    font-weight: 900;
    font-size: 24px;
    line-height: 33px;
    text-align: center;
    color: $c-black;

    &.left {
      text-align: left;
    }
  }
}

.ModalBig {
  &__body {
    max-height: 80vh;
    display: flex;
    flex-direction: column;
    position: relative;
    background: $c-white;
    border-radius: 10px;
    will-change: opacity transform;

    @include small-size-max {
      width: 100%;
      height: 100vh;
      max-height: 100vh;
      justify-content: center;
    }
  }

  &__header {
    padding: 40px 60px 40px 40px;

    @include small-size-max {
      padding: 40px;
    }
  }

  &__content {
    min-width: 500px;
    padding: 0 40px 40px;
    overflow: auto;

    @include small-size-max {
      min-width: auto;
      flex-direction: column;
    }
  }

  &__close {
    position: absolute;
    top: 40px;
    right: 40px;
    display: flex;
    color: $c-close-btn;
    font-weight: 500;
    font-size: 24px;
    line-height: 24px;
    cursor: pointer;

    @include small-size-max {
      top: 20px;
      right: 20px;
    }
  }

  &__title {
    font-family: $f-family;
    font-weight: 900;
    font-size: 36px;
    line-height: 49px;
    color: $c-black;
  }

  &__left {
    margin-right: 50px;

    @include small-size-max {
      margin-right: 0;
      margin-bottom: 50px;
    }
  }
}
