@import "../../assets/scss/setup/setup.scss";

.Modal {
  &__row {
    display: flex;
    margin-top: 20px;

    @include small-size-max {
      margin-top: 0px;
    }

    @include small-size-max {
      flex-direction: column;
    }

    div {
      margin-right: 15px;

      @include small-size-max {
        margin-right: 0;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }
  &__img {
    width: 100px;
    height: 100px;

    img {
      width: 100%;
      height: 100%;
      // height: auto;
      object-fit: contain;
    }

    cursor: pointer;
  }

  &__col__100 {
    flex: 1;

    @include small-size-max {
      margin-top: 20px;
    }

    display: flex;
    gap: 30px;
  }

  &__col__auto {
    flex: auto;

    @include small-size-max {
      margin-top: 20px;
    }
  }

  &__center {
    text-align: center;
  }

  &__password > button {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: #579D36;
    text-decoration: none;
    background: transparent !important;
    padding: 0;
    margin: 0;
    border: none;
    &:hover {
      text-decoration: underline;
      border: none;
    }
  }

  &__pleaseWait {
    text-align: center;
    padding-top: 20px;
    color: green;
  }
}

.invisible_image {
  width: 100px;
  height: 100px;
}