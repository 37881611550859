@import "../../../assets/scss/setup/setup.scss";

.Input {
  width: 100%;
  position: relative;

  & + .Input {
    margin-top: 20px;
  }

  label {
    font-family: $f-family;
    color: $c-input-color;
    display: block;
    margin-bottom: 5px;
    font-size: 18px;
    font-weight: 300;
    line-height: 25px;
    padding-left: 1px;
  }

  input:not(.date),
  textarea {
    font-family: $f-family;
    display: block;
    outline: none;
    appearance: none;
    box-sizing: border-box;
    margin: 0;
    padding: 12px 34px 12px 12px;
    width: 100%;
    background-color: $c-white;
    border: 1px solid $c-input-border;
    border-radius: 5px;
    color: $c-black;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    transition: all 300ms ease-in-out;

    &::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: black;
      opacity: .4; /* Firefox */
      -webkit-touch-callout: none;
      user-select: none;
    }

    &:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: $c-input-border;
    }

    &::-ms-input-placeholder {
      /* Microsoft Edge */
      color: $c-input-border;
    }

    &:disabled {
      background: rgba($c-input-disabled, 0.5);
      color: rgba($c-black, 0.4);
      cursor: not-allowed;
    }
  }

  input.date {
    font-family: $f-family;
    outline: none;
    appearance: none;
    box-sizing: border-box;
    margin: 0;
    padding: 12px 0px 12px 0px;
    width: 20%;
    background-color: $c-white;
    border: 0px;
    border-radius: 5px;
    color: $c-black;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    transition: all 300ms ease-in-out;
    text-align: center;
    -webkit-touch-callout: none;
    user-select: none;
  }

  .dateContainer {
    border: 1px solid $c-input-border;
    border-radius: 5px;
    display: block;
    width:100%;
    background-color: white;
    // display: flex;
    // justify-content: center;
    // align-items: center;

    & span {
      font-size: 24px;
      color: #bbbbbb;
    }

    & input:first-child {
      margin-left: 10px;
    }
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }

  input[type="date"]::-webkit-calendar-picker-indicator {
    background: transparent;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: auto;
    width: auto;
  } 

  &__error {
    margin-left: 15px;
    color: $c-error-text;
    font-size: 14px;
  }

  &.invalid {
    textarea,
    input:not(.date),
    .dateContainer {
      border: 1px solid $c-error-border;
      background-color: $c-error-bg;
    }
    input {
      background-color: $c-error-bg;
    }
  }

  &.small {
    label {
      color: $c-black;
      font-size: 12px;
      font-weight: 500;
      line-height: 16px;
    }

    input {
      max-height: 48px;
    }
  }
}

.Icon {
  position: absolute;
  bottom: 6px;
  right: 11px;
  pointer-events: none;

  svg {
    width: 24px;
    height: 24px;
  }

  &__error {
    color: $c-error-border;
  }

  &__success {
    color: $c-success;
  }

  &__date {
    bottom: 10px;
    color: $c-table-body;

    svg {
      width: 18px;
      height: 20px;
    }
  }

  &__cancel {
    position: absolute !important;
    right: 0px;
    top: -13px;
    svg {
      width: 16px;
      height: 16px;
    }
  }
}

textarea {
  resize: none;
}