@import "../../../assets/scss/setup/setup.scss";

.Title {
  font-weight: 900;
  font-size: 20px;
  line-height: 30px;
  color: $c-black;
}

.Subtitle,
.Text {
  font-size: 18px;
  line-height: 24px;
  color: $c-black;
  padding-bottom: 0;
  margin-bottom: 0;
}

.Subtitle {
  font-weight: 900;
  font-size: 16px;
}

.Text {
  font-weight: 300;
  font-size: 16px;
}

.Content {
  margin-top: 20px;
}

.Info {
  display: flex;
  margin-bottom: 20px;

  span {
    font-family: $f-family;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: $c-green;

    &:first-child {
      margin-right: 10px;
    }
  }
}

.Modal {
  &__row {
    display: flex;
    margin-top: 20px;

    @include small-size-max {
      margin-top: 0px;
    }

    @include small-size-max {
      flex-direction: column;
    }

    div {
      margin-right: 15px;

      @include small-size-max {
        margin-right: 0;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__col__100 {
    flex: 1;

    @include small-size-max {
      margin-top: 20px;
    }
  }

  &__col__auto {
    flex: auto;

    @include small-size-max {
      margin-top: 20px;
    }
  }

  &__center {
    text-align: center;
  }
}
