@import "../../assets/scss/setup/setup.scss";

.Nav {
    display: flex;
    justify-content: flex-end;

    &__link {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    @include medium-size-max {
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        min-height: 300px;
        padding-bottom: 150px;
        height: 100%;
        overflow: auto;
    }

    &_Overlay {
        @include medium-size-max {
            backdrop-filter: blur(2px);
            background-color: rgba(0, 0, 0, .08);
            left: 0;
            z-index: 9;
            width: 100%;
            transition: .3s ease;
            opacity: 0;
            visibility: hidden;
            position: absolute;

            &_Active {
                opacity: 1;
                visibility: visible;
                width: 30%;
            }
        }
    }

    &.Admin > * {
        padding-left: 2.5rem !important;
        font-size: 16px;
    }

    &.Coord > * {
        padding-left: 5rem !important;
        font-size: 16px;
    }

    > * {
        padding-left: 2.5rem;
        text-decoration: none;
        display: flex;
        background: none;
        color: $c-black;

        &:hover {
            color: $c-green;
        }

        @include medium-size-max {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            border-bottom: 1px solid $c-gray;
            padding: 25px 15px;

            &:first-of-type {
                border-top: 1px solid $c-gray;
            }
        }
    }

    &__Dropdown {
        @include medium-size-max {
            display: none;
        }

        &_Toggle {
            padding: 0 !important;
            border-width: 0px;
        }

        &_Menu {
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        }
    }

    &__Accordion {
        padding: 0;
        display: flex;
        justify-content: flex-start;

        @include large-size-min {
            display: none;
        }

        &_Item {
            border: none !important;

            &_Header {
                button {
                    padding: 25px 15px;
                    background: none !important;
                    border: none !important;
                    box-shadow: none !important;
                    color: $c-black !important;

                    &:after {
                        background-image: var(--bs-accordion-btn-icon) !important;
                        margin-left: 5px;
                    }

                    &:hover {
                        color: $c-green!important;
                    }
                }
            }

            &_Link {
                color: $c-black;
                text-decoration: none;

                &:hover {
                    color: $c-green;
                }
            }
        }
    }
}

.FirstRow {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 80px;
    position: relative;
    background-color: $c-white;
    z-index: 200;

    &_Left {
        max-width: 250px;
        width: 100%;
        display: flex;
        align-items: center;

        @include medium-size-max {
            max-width: 150px;
        }

        &_Logo {
            max-width: 250px;
            justify-content: flex-start;
            cursor: pointer;

            @include medium-size-max {
                max-width: 200px;
            }
        }

        &_Hamburger {
            display: none;

            @include medium-size-max {
                display: flex;
            }
        }
    }

    &_Profile {
        display: flex;
        align-items: center;

        @include medium-size-max {
            display: none;
        }
    }

    &_Hamburger {
        display: flex;
        width: 25px;
        height: 25px;
        position: relative;
        background: none;
        border: none;
        border-radius: 0;
        transition: .3s ease;
        cursor: pointer;

        @include large-size-min {
            display: none;
        }

        span {
            width: 100%;
            height: 2px;
            background-color: $c-black;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            transition: .3s ease;

            &:before,
            &:after {
                content: "";
                position: absolute;
                width: 100%;
                height: 2px;
                background-color: $c-black;
                left: 0;
                transition: .3s ease;
            }

            &:before {
                top: -8px;
            }

            &:after {
                bottom: -8px;
            }
        }

        &_Active {
            span {
                background-color: transparent;

                &:before {
                    top: 0;
                    transform: rotate(45deg);
                }

                &:after {
                    bottom: 0;
                    transform: rotate(-45deg);
                }
            }
        }
    }
}
.SecondRow {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 0;

    @include medium-size-max {
        padding-top: 5px;
    }

    &_User {
        display: flex;
        align-items: center;
        width: 30%;

        @include medium-size-max {
            justify-content: center;
            width: 100%;
        }

        &_Name {
            font-family: $f-family-secondary;
            color: $c-black;
            margin: 0;
            font-weight: 700;
            font-size: 18px;
            line-height: 1;
            letter-spacing: 0.05em;
            text-transform: uppercase;

            @include medium-size-max {
                font-size: 16px;
                margin-right: 25px;
            }
        }

        &_Profile {
            @include large-size-min {
                display: none;
            }
        }
    }

    &_NavWrp {
        width: 70%;
        transition: .3s ease;

        @include medium-size-max {
            flex-direction: column;
            justify-content: flex-start;
            position: absolute;
            background-color: #fff;
            max-width: 100%;
            margin: 0;
            height: 100%;
            z-index: 100;
            right: -70%;
            overflow: hidden;
        }

        &_Active {
            @include medium-size-max {
                right: 0;
            }
        }
    }
}
.FlexEnd {
    flex: 1;
    text-align: right;
}

.Training,
.ViewAsSession,
.Development {
    padding: 5px;
    height: 32px;
    background-color: #EEFFFF;
    border: solid 1px #EEEEEE;
    text-align: center;
    width: 100%;
    p {
      font-size: 16px;

        @include medium-size-max {
            font-size: 14px;
        }

        strong {
            text-transform:uppercase;
            padding-right: 8px;
        }
    }
    @include medium-size-max {
        height: 64px;
    }
}

.ViewAsSession {
    display: flex;
    align-items: center;
    justify-content: center;
}