@import "../../../assets/scss/setup/setup.scss";

.File {
  display: flex;
  margin-top: 23px;

  &:first-child {
    margin-top: 0;
  }

  &__icon {
    width: 24px;
    height: auto;
    margin-right: 23px;

    svg {
      width: 24px;
      height: auto;
    }
  }

  &__text {
    font-family: $f-family;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 22px;
    color: $c-black;
    margin-bottom: 4px;
  }

  &__more {
    display: block;
  }

  // &__wrap {
  //   max-height: 360px;
  //   overflow: auto;
  // }

  &__listContainer {
    overflow-y: auto;
    max-height: 500px;
    width: 100%;
  }
}


.Modal {
  font-family: $f-family;
  position: fixed;
  z-index: 800;
  bottom: 0px;
  right: 0px;
  left: 0px;
  top: 0px;

  &__UpdateModal {
    width: 34.3rem;
    height: 38.5rem;
  }

  &__overlay {
    position: fixed;
    bottom: 0px;
    right: 0px;
    left: 0px;
    top: 0px;
    background: rgba($c-black, 0.4);
  }

  &__wrapper {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__body {
    max-height: 80vh;
    display: flex;
    flex-direction: column;
    position: relative;
    background: $c-white;
    border-radius: 10px;
    overflow: auto;

    @include small-size-max {
      width: 100%;
      height: 100vh;
      max-height: 100vh;
    }
  }

  &__content {
    width: 675px;
    padding: 40px;

    @include medium-size-min {
      &.small {
        width: 500px;
      }

      &.big {
        width: 760px;
      }
    }

    @include small-size-max {
      width: 100%;
    }
  }

  &__button {
    width: 200px;
  }

  &__title {
    font-family: $f-family;
    font-weight: 900;
    font-size: 36px;
    line-height: 49px;
    color: $c-black;
    margin-bottom: 20px;
  }

  &__bold {
    font-family: $f-family;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: $c-black;
  }

  &__inputs {
    width: 80%;
  }

  &__text {
    font-family: $f-family;
    font-weight: 300;
    font-size: 16px;
    line-height: 22px;
    color: $c-black;
  }

  &__footer {
    margin-top: 40px;
    display: flex;
    justify-content: space-between;

    @include small-size-max {
      flex-direction: column;

      button {
        &:first-child {
          margin-bottom: 20px;
        }
      }
    }
  }
}