@import "../../assets/scss/setup/setup.scss";

.Form {
  display: flex;
  flex-wrap: wrap;
  margin: 3px -12px 0;

  &__clear {
    cursor: pointer;
    padding-left: 20px;
    color: $c-green;
    font-weight: 800;
    font-size: 12px;
  }

  &__col {
    display: flex;
    align-items: flex-end;
    margin: 12px;

    @include extra-large-size-max {
      flex: 1 1 calc(50% - 48px);
    }

    @include medium-size-max {
      flex: 1 1 calc(100% - 48px);
    }

    @include small-size-max {
      flex-direction: column;
    }

    &:last-child {
      margin-left: auto;

      @include extra-large-size-max {
        margin-left: 12px;
      }

      @include medium-size-max {
        width: 100%;

        button {
          width: 100%;
        }
      }
    }

    div {
      &:first-child {
        margin-right: 24px;

        @include small-size-max {
          margin-right: 0;
          margin-bottom: 24px;
        }
      }
    }
  }

  &__container {
    padding: 25px 0;
    background: $c-form-bg;
  }

  &__text {
    font-family: $f-family;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 25px;
    color: $c-black;
  }

  &__input__long {
    width: 190px;

    @include extra-large-size-max {
      width: 100%;
    }
  }

  &__input__small {
    width: 150px;

    @include extra-large-size-max {
      width: 100%;
    }
  }
}

.Row {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin: 30px 0;

  @include small-size-max {
    flex-direction: column-reverse;
  }
}

.Btn {
  position: relative;
  display: block;
  font-family: $f-family;
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  line-height: 22px;
  color: $c-green;
  padding: 10px 45px 10px 18px;
  border: 1px solid $c-green;
  border-radius: 5px;
  background-color: $c-lime;
  //   width: max-content;
  cursor: pointer;
  transition: all 0.3s ease;

  svg {
    position: absolute;
    right: 18px;
    top: 10px;
    // margin-left: 10px;
    // margin-bottom: -5px;
  }

  &:hover {
    background: $c-green;
    color: $c-white;
  }

  @include small-size-max {
    width: 100%;
  }
}

.Select {
  min-width: 500px;

  @include small-size-max {
    min-width: auto;
    width: 100%;
    margin-top: 20px;
  }
}

.Table {
  &__info {
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    line-height: 19px;
    padding-bottom: 15px;
    color: $c-table-body;
  }

  &__action {
    text-decoration: none;
    font-weight: 900;
  }

  &__footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 40px;
  }
}
