@import "../../assets/scss/setup/setup.scss";

.PD {
	&_Account {
		&:before,
		&:after {
			content: "";
			position: absolute;
			border: 2px solid transparent;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			border-radius: 50%;
			transition: .3s ease;
			margin: 0;
			padding: 0;
			z-index: -1;
		}

		&:before {
			width: 43px;
			height: 43px;
		}

		&:after {
			width: 49px;
			height: 49px;
		}

		&_Active {
			&:before {
				border-color: $c-green-2;
			}

			&:after {
				border-color: $c-green-3;
			}
		}

		button {
			&:active {
				border-color: transparent !important;
			}
		}

		&_Profile {
			display: flex;
			align-items: center;
			padding: 0;

			&:after {
				display: none;
			}

			&_Image {
				width: 36px;
				height: 36px;
				border-radius: 50%;
				object-fit: cover;
			}
		}

		&_Menu {
			padding: 15px 15px 20px;
			background-color: $c-yellow;
			box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
			transform: translate(0, 50px) !important;

			&_Active {
				display: flex !important;
			}

			&_Left {
				padding: 0 !important;
				display: flex !important;
				align-items: flex-start;
				margin-right: 12.5px;
				color: $c-black;
				font-size: 16px;

				&_Img {
					max-width: 65px;
				}
			}

			&_Right {
				flex-direction: column;
				padding: 0;
				margin-left: 12.5px;
				margin-top: 5px;

				&_Item {
					padding: 0;
					font-weight: 700;
					font-size: 15px;
					line-height: 1;
					text-transform: uppercase;
					font-family: $f-family-secondary;
					color: $c-black;
					transition: .3s ease;

					&:hover,
					&:active {
						background-color: unset;
						color: $c-green;
					}

					&:not(:last-of-type) {
						margin: 0 0 15px;
					}

					&_List {
						padding: 0;
						list-style: none;
						font-weight: 900;
						line-height: 1;
						min-height: 50px;
						margin-bottom: 10px;

						&_Name {
							font-weight: 900;
							font-size: 20px;
							line-height: 1;
							margin-bottom: 5px;
						}

						&_Email {
							font-size: 14px;
							line-height: 1;
							font-weight: 400;
						}
					}
				}
			}
		}
	}
}