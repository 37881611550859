@font-face {
  font-family: "Avenir";
  src: url("../../fonts/Avenir-Roman.eot");
  src: local("../../fonts/Avenir Roman"), local("../../fonts/Avenir-Roman"),
    url("../../fonts/Avenir-Roman.eot?#iefix") format("embedded-opentype"),
    url("../../fonts/Avenir-Roman.woff2") format("woff2"),
    url("../../fonts/Avenir-Roman.woff") format("woff"),
    url("../../fonts/Avenir-Roman.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

// @font-face {
//   font-family: 'Roboto Mono';
//   src: url('../../fonts/RobotoMono-Regular.ttf') format('truetype');
//   font-weight: normal;
//   font-style: normal;
// }

@font-face {
  font-family: "Avenir";
  src: url("../../fonts/Avenir-Oblique.eot");
  src: local("../../fonts/Avenir Oblique"), local("../../fonts/Avenir-Oblique"),
    url("../../fonts/Avenir-Oblique.eot?#iefix") format("embedded-opentype"),
    url("../../fonts/Avenir-Oblique.woff2") format("woff2"),
    url("../../fonts/Avenir-Oblique.woff") format("woff"),
    url("../../fonts/Avenir-Oblique.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Avenir";
  src: url("../../fonts/Avenir-Light.eot");
  src: local("../../fonts/Avenir Light"), local("../../fonts/Avenir-Light"),
    url("../../fonts/Avenir-Light.eot?#iefix") format("embedded-opentype"),
    url("../../fonts/Avenir-Light.woff2") format("woff2"),
    url("../../fonts/Avenir-Light.woff") format("woff"),
    url("../../fonts/Avenir-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Avenir";
  src: url("../../fonts/Avenir-LightOblique.eot");
  src: local("../../fonts/Avenir Light Oblique"),
    local("../../fonts/Avenir-LightOblique"),
    url("../../fonts/Avenir-LightOblique.eot?#iefix")
      format("embedded-opentype"),
    url("../../fonts/Avenir-LightOblique.woff2") format("woff2"),
    url("../../fonts/Avenir-LightOblique.woff") format("woff"),
    url("../../fonts/Avenir-LightOblique.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Avenir";
  src: url("../../fonts/Avenir-Medium.eot");
  src: local("../../fonts/Avenir Medium"), local("../../fonts/Avenir-Medium"),
    url("../../fonts/Avenir-Medium.eot?#iefix") format("embedded-opentype"),
    url("../../fonts/Avenir-Medium.woff2") format("woff2"),
    url("../../fonts/Avenir-Medium.woff") format("woff"),
    url("../../fonts/Avenir-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Avenir";
  src: url("../../fonts/Avenir-MediumOblique.eot");
  src: local("../../fonts/Avenir Medium Oblique"),
    local("../../fonts/Avenir-MediumOblique"),
    url("../../fonts/Avenir-MediumOblique.eot?#iefix")
      format("embedded-opentype"),
    url("../../fonts/Avenir-MediumOblique.woff2") format("woff2"),
    url("../../fonts/Avenir-MediumOblique.woff") format("woff"),
    url("../../fonts/Avenir-MediumOblique.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "Avenir";
  src: url("../../fonts/Avenir-Heavy.eot");
  src: local("../../fonts/Avenir Heavy"), local("../../fonts/Avenir-Heavy"),
    url("../../fonts/Avenir-Heavy.eot?#iefix") format("embedded-opentype"),
    url("../../fonts/Avenir-Heavy.woff2") format("woff2"),
    url("../../fonts/Avenir-Heavy.woff") format("woff"),
    url("../../fonts/Avenir-Heavy.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Avenir";
  src: url("../../fonts/Avenir-HeavyOblique.eot");
  src: local("../../fonts/Avenir Heavy Oblique"),
    local("../../fonts/Avenir-HeavyOblique"),
    url("../../fonts/Avenir-HeavyOblique.eot?#iefix")
      format("embedded-opentype"),
    url("../../fonts/Avenir-HeavyOblique.woff2") format("woff2"),
    url("../../fonts/Avenir-HeavyOblique.woff") format("woff"),
    url("../../fonts/Avenir-HeavyOblique.ttf") format("truetype");
  font-weight: 900;
  font-style: italic;
}

/* latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCuM73w5aXp-p7K4KLg.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

// @font-face {
//     font-family: 'Avenir Book';
//     src: url('../../fonts/Avenir-BookOblique.eot');
//     src: local('../../fonts/Avenir Book Oblique'), local('../../fonts/Avenir-BookOblique'),
//         url('../../fonts/Avenir-BookOblique.eot?#iefix') format('embedded-opentype'),
//         url('../../fonts/Avenir-BookOblique.woff2') format('woff2'),
//         url('../../fonts/Avenir-BookOblique.woff') format('woff'),
//         url('../../fonts/Avenir-BookOblique.ttf') format('truetype');
//     font-weight: normal;
//     font-style: italic;
// }

// @font-face {
//     font-family: 'Avenir Black Oblique';
//     src: url('../../fonts/Avenir-BlackOblique.eot');
//     src: local('../../fonts/Avenir Black Oblique'), local('../../fonts/Avenir-BlackOblique'),
//         url('../../fonts/Avenir-BlackOblique.eot?#iefix') format('embedded-opentype'),
//         url('../../fonts/Avenir-BlackOblique.woff2') format('woff2'),
//         url('../../fonts/Avenir-BlackOblique.woff') format('woff'),
//         url('../../fonts/Avenir-BlackOblique.ttf') format('truetype');
//     font-weight: 900;
//     font-style: italic;
// }

// @font-face {
//     font-family: 'Avenir Book';
//     src: url('../../fonts/Avenir-Book.eot');
//     src: local('../../fonts/Avenir Book'), local('../../fonts/Avenir-Book'),
//         url('../../fonts/Avenir-Book.eot?#iefix') format('embedded-opentype'),
//         url('../../fonts/Avenir-Book.woff2') format('woff2'),
//         url('../../fonts/Avenir-Book.woff') format('woff'),
//         url('../../fonts/Avenir-Book.ttf') format('truetype');
//     font-weight: normal;
//     font-style: normal;
// }

// @font-face {
//     font-family: 'Avenir';
//     src: url('../../fonts/Avenir-Black.eot');
//     src: local('../../fonts/Avenir Black'), local('../../fonts/Avenir-Black'),
//         url('../../fonts/Avenir-Black.eot?#iefix') format('embedded-opentype'),
//         url('../../fonts/Avenir-Black.woff2') format('woff2'),
//         url('../../fonts/Avenir-Black.woff') format('woff'),
//         url('../../fonts/Avenir-Black.ttf') format('truetype');
//     font-weight: 900;
//     font-style: normal;
// }


