$f-family: 'Avenir';
$f-family-secondary: 'Montserrat';

$c-border: #c4c4c4;
$bg-table-head: #eeeeee;
$c-table-head: #999999;

$bg-table-body: #f9fafc;
$c-table-body: #6d6d6e;

$c-input-border: #E5E5E5;
$c-input-color: #808080;
$c-input-disabled: #d9d9d9;

$c-link-blue:#2c6fae;
$c-link-blue-l:#1671C5;

$c-icon:#747474;

$c-close-btn: #A0A0A0;
$c-secondary-btn: #8F8F8F;

$c-form-bg: #fafafa;

$c-error-text:#A11E1E;
$c-error-border:#FE0000;
$c-error-bg:#FFEDED;
$c-success:#396907;

$c-white: #fff;
$c-white-50: #ffffff80;
$c-black: #000;
$c-lime: #ebf1da;
$c-green: #579d36;
$c-green-2: #00AD43;
$c-green-3: #78D341;
$c-gray: #817676;
$c-gray-2: #777777;
$c-blue: #5292e9;
$c-orange: #F9DDB3;
$c-yellow: #FDF8DC;

$c-font: #123456;