@import "../../../assets/scss/setup/setup.scss";

.container {
    display: flex;
    align-items: center;
    height: 100%;
}

.sel {
    height: 40px;
    width: 550px;
    background: none;
    border: 1px solid #C4C4C4;
    border-radius: 5px;
    padding: 10px 10px 10px 10px;
}

.modal_inner {
    width: 40vw;
    min-height: 30vh;
}

.selector {
    margin-top: 15px;
}

.container>button {
    height: 100%;
    height: 40px;
    margin-left: 5px;
    padding: 5px 20px 5px 20px;
    border-radius: 6px;
    background: $c-green;
    color: white;
    font-family: $f-family;
    font-style: normal;
    font-weight: 800;
    border: 1px solid transparent;
    transition-duration: 250ms;
    transition-property: background border-color;

    &:hover {
        background: $c-white;
        border-color: $c-green;
        color: $c-green;
    }
}