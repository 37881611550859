@import "../../assets/scss/setup/setup.scss";

.Row {
  box-sizing: border-box;
  display: flex;
  margin-top: 20px;

  @include small-size-max {
    margin-top: 0px;
    flex-direction: column;
  }

  @include small-size-min {
    &_narrow {
      margin-left: 30px;
      margin-right: 30px;
    }
  }

  div {
    margin-right: 25px;

    @include small-size-max {
      margin-right: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}

.Col {
  &__50 {
    width: calc(50% - 12.5px);

    @include small-size-max {
      width: 100%;
      margin-top: 20px;
    }
  }

  &__100 {
    flex: 1;

    @include small-size-max {
      margin-top: 20px;
    }
  }

  &__side_label {
    display: flex;
    align-items: flex-end;
    position: relative;

    > span {
      color: $c-input-color;
      font-size: 18px;
      font-weight: 300;
      line-height: 25px;
      padding-bottom: 12px;
      margin-right: 5px;
    }

    div + span {
      margin-right: 0;
      margin-left: 5px;

      @include medium-size-min {
        margin-left: -20px;
      }
    }

    > div {
      padding-top: 30px;
      position: static;

      label {
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }
}

.Action {
  font-family: $f-family;
  font-style: normal;
  font-size: 18px;
  line-height: 25px;
  font-weight: 800;
  color: $c-link-blue;
  cursor: pointer;
}

.Label {
  color: $c-input-color;
  margin-bottom: 5px;
  font-size: 18px;
  font-weight: 300;
  line-height: 25px;
  padding-left: 1px;
}

.Error_label {
  margin-left: 15px;
  color: $c-error-text;
  font-size: 14px;
}

.Upload {
  display: flex;
  justify-content: space-between;

  button {
    align-self: flex-start;
    white-space: nowrap;
    margin-right: 20px;
    width: 170px;
  }

  &_files {
    cursor: pointer;

    img {
      max-width: 200px;
      max-height: 100px;
      object-fit: contain;
    }

    input {
      display: none;
    }

    &_preview {
      display: flex;

      svg {
        margin-left: 5px;

        path {
          fill: $c-input-color;
        }
      }
    }
  }
}