@import "../../../assets/scss/setup/setup.scss";

.Charts {
  margin-top: 50px;
  margin-bottom: 50px;

  &__Col {
    &_Cashflow {
      @include medium-size-max {
        margin-top: 30px;
      }
    }

    &_Wrp {
      display: flex;
      flex-direction: column;
      border: 1px solid $c-border;
      border-radius: 15px;
      padding: 25px 0 15px;
      height: 100%;

      h2 {
        color: $c-black;
        font-size: 30px;
        font-weight: 300;
      }

      &_Title {
        padding: 0 25px 15px;

        @include large-size-max {
          display: flex;
          flex-direction: column;
        }

        &_Select {
          border: 1px solid $c-input-border;
          border-radius: 5px;
          max-width: 200px;
          width: 100%;
          height: 50px;
        }
      }

      &_Chart {
        height: 400px;
      }
    }
  }
}