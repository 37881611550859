@import "../../assets/scss/setup/setup.scss";

.HM {
  &_Dropdown {
    padding-right: 15px;

    &_Toggle {
      &:after {
        display: none;
      }
    }

    &_Menu {
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

      &_Active {
        display: grid!important;
        grid-template-columns: repeat(3, 1fr);
        grid-auto-rows: 1fr;
        padding: 15px;
      }

      &_Item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 7.5px;
        border-radius: 5px;

        &:active {
          background-color: var(--bs-dropdown-link-hover-bg);
        }

        &_Profile {
          border-radius: 50%;
          width: 38px;
          height: 38px;
        }

        &_Img {
          &_Wrp {
            height: 44px;
            display: flex;
            align-items: center;
            margin-bottom: 10px;
          }
        }

        &_Text {
          text-align: center;
          padding: 0;
          flex-grow: 1;
          font-size: 16px;
          color: $c-black;

          @include small-size-max {
            font-size: 13px;
          }
        }
      }
    }
  }
}
