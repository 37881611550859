@import "../../assets/scss/setup/setup.scss";

.Title {
  font-family: $f-family;
  font-style: normal;
  font-weight: 300;
  font-size: 36px;
  line-height: 49px;
  color: $c-black;
}

.Text {
  font-family: $f-family;
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 25px;
  color: $c-input-color;

  span {
    cursor: initial;

    &:hover {
      text-decoration: none;
    }
  }
}

.Action {
  font-family: $f-family;
  font-style: normal;
  font-size: 18px;
  line-height: 25px;
  font-weight: 800;
  color: $c-link-blue;
  cursor: pointer;
}

.Heading {
  display: flex;

  @include small-size-max {
    flex-direction: column;
  }
}

.Message {
  color: #AA0000;
  font-size: 16px;
  padding-top: 20px;
}

.Img {
  width: 154px;
  height: auto;
  margin-right: 30px;

  @include small-size-max {
    margin-right: 0;
    margin-bottom: 20px;
  }
}

.Row {
  box-sizing: border-box;
  display: flex;
  margin-top: 20px;

  @include small-size-max {
    margin-top: 0px;
  }

  @include small-size-max {
    flex-direction: column;
  }

  div {
    margin-right: 25px;

    @include small-size-max {
      margin-right: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}

.Col {
  &__50 {
    width: calc(50% - 12.5px);

    @include small-size-max {
      width: 100%;
    }
  }

  &__100 {
    flex: 1;

    @include small-size-max {
      margin-top: 20px;
    }
  }
}

.Modal {
  font-family: $f-family;
  position: fixed;
  z-index: 900;
  bottom: 0px;
  right: 0px;
  left: 0px;
  top: 0px;

  &__overlay {
    position: fixed;
    bottom: 0px;
    right: 0px;
    left: 0px;
    top: 0px;
    z-index: 400;
    background: rgba($c-black, 0.4);
  }

  &__wrapper {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 800;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__body {
    max-height: 80vh;
    display: flex;
    flex-direction: column;
    position: relative;
    background: $c-white;
    border-radius: 10px;
    overflow: auto;

    @include small-size-max {
      width: 100%;
      height: 100vh;
      max-height: 100vh;
      // justify-content: center;
    }
  }

  &__content {
    width: 675px;
    padding: 40px;

    @include medium-size-min {
      &.small {
        width: 500px;
      }

      &.big {
        width: 760px;
      }
    }

    @include small-size-max {
      width: 100%;
    }
  }

  &__title {
    font-family: $f-family;
    font-weight: 900;
    font-size: 36px;
    line-height: 49px;
    color: $c-black;
    margin-bottom: 20px;
  }

  &__subtitle {
    font-family: $f-family;
    font-weight: 300;
    font-size: 36px;
    line-height: 49px;
    color: $c-black;
    // margin-bottom: 20px;
  }

  &__text {
    font-family: $f-family;
    font-weight: 300;
    font-size: 16px;
    line-height: 18px;
    color: $c-black;
    &___review {
      line-height: 14px;
    }
  }

  &__footer {
    margin-top: 40px;
    display: flex;
    justify-content: space-between;

    @include small-size-max {
      flex-direction: column;

      button {
        &:first-child {
          margin-bottom: 20px;
        }
      }
    }
  }

  &__row {
    display: flex;

    @include small-size-max {
      flex-direction: column-reverse;
    }
  }

  &__row__center {
    display: flex;
    align-items: center;

    @include small-size-max {
      flex-direction: column;      
    }
  }

  &__left {
    flex: 1;

    @include small-size-max {
      margin-top: 25px;
    }
  }

  &__right {
    flex: 1;
  }

  &__img {
    width: 154px;
    height: auto;
    margin-bottom: 10px;
  }

  &__copy {
    display: flex;

    &_icon {
      cursor: pointer;
      margin-left: 8px;
      margin-top: -5px;
      color: $c-green;

      svg {
        pointer-events: none;
      }
    }
  }

  &__loader {
    margin-right: 20px;

    @include small-size-max {
      margin-right: 0;
      margin-bottom: 20px;
    }
  }
}

.SubModal {
  font-family: $f-family;
  color:$c-green;
  cursor: pointer;
}