@import "../../../assets/scss/setup/setup.scss";

.Mb {
  margin-top: 50px;
  margin-bottom: 50px;

  &__Col {
    &_Wrp {
      padding: 25px;
      border: 1px solid $c-border;
      border-radius: 15px;
    }

    &_Title {
      color: $c-black;
      font-size: 30px;
      font-weight: 300;
      margin-bottom: 40px;
    }

    &_Info {
      margin-top: 25px;
      display: none;

      &_Active {
        display: flex;
        justify-content: space-between;
      }

      &_Updated {
        margin-bottom: 0;
        font-size: 12px;
        font-weight: 900;
        color: $c-gray-2;
      }

      &_Edit {
        color: $c-black;
        text-decoration: none;
        appearance: none;
        border: none;
        background: none;

        &:hover {
          text-decoration: underline;
          color: $c-black;
        }

        img {
          margin-right: 10px;
        }
      }
    }

    &_Save {
      margin-top: 25px;
      display: none;

      &_Active {
        display: flex;
        justify-content: space-between;
      }

      &_Active {
        display: flex;
        justify-content: space-between;
      }

      &_Cancel,
      &_Save {
        padding: 15px 0;
        border-radius: 5px;
        border: 1px solid $c-green;
        background: none;
        font-weight: 900;
        max-width: 115px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: .3s ease;
      }


      &_Cancel {
        color: $c-green;

        &:hover {
          color: $c-white;
          background: $c-green;
        }
      }

      &_Save {
        color: $c-white;
        background: $c-green;

        &:hover {
          color: $c-green;
          background: $c-white;
        }
      }
    }
  }
}

h1 {
  font-family: $f-family;
  font-style: normal;
  font-weight: 300;
  font-size: 36px;
  line-height: 49px;
  color: $c-black;
  margin-bottom: 25px;
}