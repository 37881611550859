@import "../../../assets/scss/setup/setup.scss";

.HelpfulInformation {
  p,
  a,
  span {
    font-family: $f-family;
    font-style: normal;
    font-size: 16px;
    line-height: 22px;
  }

  p {
    font-weight: 300;
    color: $c-black;
    margin-bottom: 25px;
  }

  a {
    display: block;
    font-weight: 900;
    color: $c-green;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

h1 {
  font-family: $f-family;
  font-style: normal;
  font-weight: 300;
  font-size: 36px;
  line-height: 49px;
  color: $c-black;
  margin-bottom: 25px;
}

h1>* {
  font-family: $f-family !important;
  font-style: normal !important;
  font-size: 36px !important;
  line-height: 49px !important;
  margin-bottom: 25px !important;
}

h1>*>* {
  font-family: $f-family !important;
  font-style: normal !important;
  font-size: 36px !important;
  line-height: 49px !important;
  margin-bottom: 25px !important;
}

h1>*>*>* {
  font-family: $f-family !important;
  font-style: normal !important;
  font-size: 36px !important;
  line-height: 49px !important;
  margin-bottom: 25px !important;
}

h1>*>*>*>* {
  font-family: $f-family !important;
  font-style: normal !important;
  font-size: 36px !important;
  line-height: 49px !important;
  margin-bottom: 25px !important;
}

h1>*>*>*>*>* {
  font-family: $f-family !important;
  font-style: normal !important;
  font-size: 36px !important;
  line-height: 49px !important;
  margin-bottom: 25px !important;
}