@import "../../assets/scss/setup/setup.scss";
.Footer {
	background-color: #F0F0F0;
	height: 200px;
	display: block;
	padding-top: 30px;
	text-align: center;
	font-size: 15px;
	margin-top: 75px;
}
.VisitBlog {
	margin-top: 10px;
	> a {
		color: #579d36;
		font-weight: bold;
		text-decoration: none;
	}
}
