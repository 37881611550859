@import "../../assets/scss/setup/setup.scss";

.Table {
  &__info {
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    line-height: 19px;
    padding-bottom: 15px;
    color: $c-table-body;
  }

  &__action {
    text-decoration: none;
    font-weight: 900;
    display: flex;
    flex-direction: column;

    span {
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__username {
    color: #999999;
    font-weight: 100;
  }

  &__email {
    color: $c-green !important;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  &__footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 40px;
  }

  &.status {
    text-transform: capitalize;
  }

}


.Modal {
  &__wrapper {
    display: flex;
  }

  &__left {
    flex: 1;
    margin-right: 25px;
  }

  &__img {
    width: 100px;
    height: 100px;

    img {
      width: 100%;
      height: 100%;
      // height: auto;
      object-fit: contain;
    }
  }

  &__center {
    text-align: center;
  }

  &__row {
    display: flex;
    margin-top: 20px;

    @include small-size-max {
      margin-top: 0px;
    }

    @include small-size-max {
      flex-direction: column;
    }

    div {
      margin-right: 30px;

      @include small-size-max {
        margin-right: 0;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__col__100 {
    flex: 1;

    @include small-size-max {
      margin-top: 20px;
    }
    display: flex;
    gap: 20px;
  }

  &__col__auto {
    flex: auto;

    @include small-size-max {
      margin-top: 20px;
    }
  }
}

.AdminModal {
  > * {
    margin-top: 10px;
  }
}