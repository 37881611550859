@import "../../../assets/scss/setup/setup.scss";

.Button {
  background: transparent;
  border-radius: 5px;
  border: 1px solid transparent;
  font-family: $f-family;
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: $c-white;
  padding: 15px 20px;
  transition: all 0.3s ease;
  cursor: pointer;

  &.primary {
    background: $c-green;

    &:hover {
      background: $c-white;
      border-color: $c-green;
      color: $c-green;
    }
  }

  &.secondary {
    background: $c-secondary-btn;
    color: $c-white;

    &:hover {
      background: $c-white;
      border-color: $c-secondary-btn;
      color: $c-secondary-btn;
    }
  }

  &.lightgreen {
    border: 1px solid #579D36;
    background: #EBF1DA;
    color: #579D36;

    &:hover {
      background: white;
      border-color: #EBF1DA;
    }
  }

  &:disabled {
    pointer-events: none;
    background: rgba($c-input-disabled, 0.5);
    color: rgba($c-black, 0.4);
    cursor: not-allowed;
  }

  &.small {
    padding: 12px 45px;
  }
}
