.spinner {
    border: 4px solid #f3f3f3;
    border-top: 4px solid #00AD43;
    border-right: 4px solid #78D341;
    border-bottom: 4px solid #FCDE1D;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    margin: auto;
}

.content-box {
    /* Set other styles as needed */
}
