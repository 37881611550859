@import "../../../assets/scss/setup/setup.scss";

.Wrapper {
  margin-top: 50px;
}

.DropZone {
  width: 100%;
  height: 164px;
  border: 3px dashed $c-border;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;

  &.active {
    border-color: $c-green;
  }

  &__text {
    font-family: $f-family;
    font-style: normal;
    font-weight: 800;
    font-size: 36px;
    line-height: 49px;
    text-align: center;
    color: $c-table-head;
    text-align: center;
    user-select: none;
  }

  &__input {
    border: 1px solid $c-border;
    margin-right: 25px;
    display: flex;
    align-items: center;
    padding: 15px;
    cursor: pointer;

    @include xs-size-max {
      margin-right: 0;
      margin-bottom: 25px;
    }

    &__btn {
      font-family: "Arial";
      margin-right: 10px;
      padding: 1px 2px;
    }

    &__text {
      font-family: "Arial";
      font-size: 13px;
      font-weight: 400;
    }
  }

  &__btn {
    @include xs-size-max {
      width: 100%;
    }

    button {
      @include xs-size-max {
        width: 100%;
      }
    }
  }
}

.Row {
  margin-top: 25px;
  display: flex;
  align-items: center;

  @include xs-size-max {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }
}
