@import "../../../assets/scss/setup/setup.scss";

.Pagination {
  display: flex;

  button {
    padding: 10px 15px;
    background-color: $c-white;
    border: 1px solid $bg-table-head;
    border-right: 0;
    box-sizing: border-box;
    font-weight: 800;
    font-size: 14px;
    line-height: 14px;
    color: $c-table-body;
    cursor: pointer;
    transition: all .3s ease-in-out;

    &:last-child {
        border-right: 1px solid $bg-table-head;
    }

    &.active,
    &:hover {
        background-color: $c-blue;
        color: $c-white;
    }

    &:disabled {
        opacity: 0.3;
        pointer-events: none;
    }
  }

  &__btn {
    display: flex;
    align-items: center;
    position: relative;

    svg {
        width: 12px;
        pointer-events: none;
    }

    span {
        pointer-events: none;
    }

    &__prev {
        span {
            margin-left: 5px;
        }
    }

    &__next {
        span {
            margin-right: 5px;
        }
    }
  }

  &.disabled {
    opacity: 0.3;    
    pointer-events: none;

    button {
        opacity: 1;
    }
  }
}
