@import "../../assets/scss/setup/setup.scss";

.fill_width {
  width: -moz-available;          /* For Mozzila */
  width: -webkit-fill-available;  /* For Chrome */
  width: stretch;                 /* Unprefixed */
}

.bulk_action {
  margin-left: 25px;
}

.bulk_select {
  height: 100%;
}

.Form {
  display: flex;
  flex-wrap: wrap;

  &__clear {
    cursor: pointer;
    padding-left: 20px;
    color: $c-green;
    font-weight: 800;
    font-size: 12px;
  }

  &__filter {
    position: relative;
    display: flex;
    cursor: pointer;
    float: right;
    padding-left: 20px;
    color: $c-green;
    font-weight: 800;
    font-size: 12px;
    gap: 10px;
  }

  &__filter__icon {
    color: $c-black;
  }

  &__col {
    display: flex;
    align-items: flex-end;
    margin: 12px 0;

    @include extra-large-size-max {
      flex: 1 1 calc(50% - 48px);
    }

    @include medium-size-max {
      flex: 1 1 calc(100% - 48px);
    }

    @include small-size-max {
      flex-direction: column;
      margin: 5px 0;
    }

    &:last-child {
      margin-left: auto;

      @include extra-large-size-max {
        margin-left: 12px;
      }

      @include medium-size-max {
        width: 100%;

        button {
          width: 100%;
        }
      }
    }

    div {
      &:first-child {
        margin-right: 24px;

        @include small-size-max {
          margin-right: 0;
          margin-bottom: 0;
        }
      }
    }
  }

  &__container {
    padding: 25px 0;
    background: $c-form-bg;
  }

  &__table_container {
    padding: 0px 0;
    background: $c-form-bg;
  }

  &__text {
    font-family: $f-family;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 25px;
    color: $c-black;
  }

  &__input__long {
    width: 190px;

    @include extra-large-size-max {
      width: 100%;
    }
  }

  &__input__small {
    width: 150px;

    @include extra-large-size-max {
      width: 100%;
    }
  }

  &__filter__dropdown__menu {
    all: initial;
    position: absolute;
    top: 100%;
    background-color: #f9f9f9;
    border: 1px solid #ccc;
    border-top: none;
    width: max-content;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 2;
    overflow-y: auto;
    max-height: 275px;
  }

  &__filter__dropdown__item {
    all: initial;
    font-family: $f-family;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    padding: 12px 16px;
    cursor: pointer;
    display: block;
    transition: background-color 0.3s;
    height: 18px;
  }

  &__filter__dropdown__item:hover {
    background-color: #ddd;
  }

  &__dynamic__inputs {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    width: 1070px;
  }

  &__dynamic__inputs>* {
    margin-left: 0 !important;
  }

  &__checkbox_container {
    display: flex;
    flex-direction: row;
    gap: 8px;
  }

  &__checkbox {
    cursor: pointer;
  }

  &__checkbox_label {
    margin-top: 2px;
    cursor: pointer;
    font-family: $f-family;
    font-style: normal;
    font-weight: 300;
    font-size: 15px;
    line-height: 25px;
    color: $c-black;
  }
}

.Row {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin: 30px 0;

  @include small-size-max {
    flex-direction: column-reverse;
  }
}

.DownloadCol {
  margin-left: auto;
}

.Btn {
  position: relative;
  display: block;
  font-family: $f-family;
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  line-height: 22px;
  color: $c-green;
  padding: 10px 45px 10px 18px;
  border: 1px solid $c-green;
  border-radius: 5px;
  background-color: $c-lime;
  //   width: max-content;
  cursor: pointer;
  transition: all 0.3s ease;

  svg {
    position: absolute;
    right: 18px;
    top: 10px;
    // margin-left: 10px;
    // margin-bottom: -5px;
  }

  &:hover {
    background: $c-green;
    color: $c-white;
  }

  @include small-size-max {
    width: 100%;
  }
}

.Select {
  max-width: 190px;

  @include small-size-max {
    max-width: 100%;
    width: 100%;
  }
}

.Bulk_Actions {
  background-color: #fff;
  border: 1px solid #E5E5E5;
  border-radius: 5px;
  background: fixed;

  &__Toggle {
    background-color: #fff;
    color: black;
    width: 100%;
  }

}

.Checkbox {
  display: flex;
  align-self: center;
  margin-top: 14px;
}