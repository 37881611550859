.notificationContainter {
    position: fixed;
    display: flex;
    bottom: 20px;
    right: 20px;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;
    gap: 10px;
}

.notification {
    padding: 20px 20px 20px 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 600px;
    min-height: 100px;
}

.notification>.buttonContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-weight: bold;
    width: 100px;
    cursor: pointer;
}